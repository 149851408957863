.reasons {
    display: flex;
    padding: 0 2rem;
    gap: 2rem;
}

.reasons-left {
    display: grid;
    flex:  1 1;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.reasons-left>img {
    object-fit: cover;
}

.reasons-left>:nth-child(1) {
    width: 12rem;
    grid-row: 1/3;
    height: 28rem;
}

.reasons-left>:nth-child(2) {
    width: auto;
    grid-column: 2/4;
    height: 16rem;
}

.reasons-left>:nth-child(3) {
    width: 14rem;
    grid-column: 2/3;
    grid-row: 2;
}

.reasons-left>:nth-child(4) {
    width: 10rem;
    grid-column: 3/4;
    grid-row: 2;
}


.reasons-right {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 1rem;
    text-transform: uppercase;
    color: white;
    font-weight: bold;
}

.reasons-right>:nth-child(1) {
    color: var(--orange);
}

.reasons-right>:nth-child(2) {
    font-size: 3rem;
}

.reasons-points {
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: 1rem;
}

.tick {
    width: 2rem;
    height: 2rem;
}

.reasons-right>:nth-child(7) {
    color: var(--gray);
    font-weight: normal;
}

.reasons-right>:nth-child(8) {
    display: flex;
    gap: 1rem;
}

.reasons-right>:nth-child(8)>img {
    width: 2.5rem;
}

@media screen and (max-width: 768px) {
    .reasons {
        flex-direction: column;
    }
    .reasons-left {
        grid-auto-rows: auto;
        overflow: hidden;
    }
    .reasons-left > :nth-child(1) {
        width: 7rem;
        height: 17rem;
    }
    .reasons-left > :nth-child(2) {
        width: 15rem;
        height: 10rem;
    }
    .reasons-left > :nth-child(3) {
        width: 7rem;
    }
    .reasons-left > :nth-child(4) {
        width: 7rem;
        height: 6rem;
    }
}


