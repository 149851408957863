.join-container {
    display: flex;
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    gap: 12rem;
    padding: 0 2rem;
}

.left-j {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 3rem;
    position: relative;
}

.left-j>div {
    display: flex;
    gap: 1rem;
}

.left-j>hr {
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
    position: absolute;
    width: 10.5rem;
}



.right-j>form {
    display: flex;
    padding: 1rem;
    background-color: grey;
    gap: 2rem;
    
}

.right-j>form>button {
    width: 6rem;
    line-height: 2.5rem;
    background-color:var(--orange);
    border: none;
    color: white;
    font-weight: bolder;
    cursor: pointer;
    transition: all .3s;
}

.right-j>form>input {
    background-color: initial;
    border: none;
    text-overflow: hidden;
    color: white;
    outline: none;
}

.right-j {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

@media screen and (max-width: 768px) {
    .join-container {
        flex-direction: column;
        gap: 1rem;
    }

    .left-j {
        font-size: x-large;
        flex-direction: column;
    }
}