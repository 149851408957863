.footer-container {
    border-top: 2px solid var(--lightgray);
    height: 22.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    position: relative;
    padding: 0 2rem;
}

.upper {
    display: flex;
    gap: 4rem;
}

.upper>img {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}

.lower>img {
    width: 10rem;
}


.footer-blur-l {
    bottom: 0;
    left: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: rgb(255, 115, 0);
}

.footer-blur-r {
    bottom: 0;
    right: 15%;
    width: 26rem;
    height: 12rem;
    filter: blur(200px);
    background: red;
}